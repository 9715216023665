import { ISearchConfig } from '@/components/SearchTable/index.d';

export const searchConfig: ISearchConfig[] = [
  {
    category: 'common_content',
    subclass: 'name',
    type: 'input',
    key: 'name'
  },
  {
    category: 'common_content',
    subclass: 'employeeId',
    type: 'input',
    key: 'employeeId'
  },
  {
    category: 'common_content',
    subclass: 'email',
    type: 'input',
    key: 'email'
  },
  {
    category: 'common_content',
    subclass: 'externalContact',
    type: 'input',
    key: 'externalContact'
  },
  {
    category: 'common_content',
    subclass: 'letter',
    type: 'input',
    key: 'letter'
  },
  {
    category: 'common_content',
    subclass: 'createTime',
    extendType: 'datetimerange',
    type: 'datepicker',
    key: 'createTime',
    valueformat: 'yyyy-MM-dd HH:mm:ss'
  }
];
