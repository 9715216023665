import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class ChatrecordcheckService {
  @Inject(HttpService) private http!: HttpService;

  public getWeChatMessage(param: Dict<any>): Promise<any> {
    return this.http.post('api/wechatMessage/getWeChatMessage', param);
  }

  public async getChatFileDataUrl(param:any,name:any): Promise<any> {
    return this.http.downloadFiles('api/wechatMessage/getChatFileData',{},param,name);
  }

  public async wechatDownload(param: Dict<any>,name?: string): Promise<any> {
    return this.http.downloadFiles(
      `api/wechatMessage/download`,
      {},
      param,
      name
    );
  }

}