

















































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { searchConfig } from './chatrecordcheck.config';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { ChatrecordcheckService } from '@/services/chatrecordcheck-service';
import { cloneDeep, isArray } from 'lodash';
import moment from 'moment';

@Component
export default class Chatrecordcheck extends Vue {
  public searchFormLoading: boolean = false;
  public loading: boolean = false;
  private downloading: boolean = false;

  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Inject(ChatrecordcheckService) private chatrecordcheckService!: ChatrecordcheckService;

  private searchConfig: ISearchConfig[] = [];
  private tableData: Dict<any> = [];
  private form: any = {};
  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };

  private async getList(val?: any, type?: string) {
    let form: any = { pageNum: 1, pageSize: 10 };
    if (type === 'pagination') {
      // console.log('pagination', val, this.form);
      const { pageSize, pageNum } = val;
      form.pageNum = pageNum;
      form.pageSize = pageSize;
      form = Object.assign({}, this.form, form);
    } else if (type === 'search') {
      form = Object.assign(form, val);
      form.pageSize = 10;
      form.pageNum = 1;
    }
    if (form.createTime && isArray(form.createTime)) {
      form.timeFrom = form.createTime[0];
      form.timeTo = form.createTime[1];
    }else{
      form.timeFrom = '';
      form.timeTo = '';
    }
    this.form = form;
    try {
      this.loading = true;
      const params = {...this.form}
      delete params.createTime
      const res = await this.chatrecordcheckService.getWeChatMessage(params);
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      // console.log('records', records);
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.pagination.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
      }
    } finally {
      this.loading = false;
    }
  }

  private async mounted(): Promise<void> {
    this.searchConfig = cloneDeep(searchConfig);
    this.getList();
  }

  //重置按钮
  private selectChange(val: Dict<any>): void {
    this.form = val;
    this.getList();
  }

  // 下载方法
  private async successdownload(): Promise<void> {
    this.downloading = true;
    const date = new Date();
    this.chatrecordcheckService.wechatDownload(
      this.form,
      `聊天记录检查${moment(date).format('YYYYMMDDHHmm')}.xlsx`
    ).then(res=>{
      this.downloading = false;
    });
  }

  private chatkeywordsHighlight(val: any, keyword: any): void {
    val = val + '';
    if(val.indexOf(keyword) !== -1 && keyword !== '' ){
      return val.replace(keyword, `<span style="background-color:yellow;">${keyword}</span>`);
    }else{
      return val;
    }
  }
  
  private async letterDown(row: any): Promise<void> {
    this.downloading = true;
    const {id,url} = row;
    const name = url && url.split('/')[url.split('/').length - 1]
    this.chatrecordcheckService.getChatFileDataUrl({id}, name).then(res=>{
      this.downloading = false;
    })
  }

}
